@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

  body {
    @apply bg-blueH1 text-smokeH  font-montserrat overflow-x-hidden
  }

  h1, h2, h3, h4, h5, h6 {
    @apply text-smokeH  font-extrabold
  }

  h1 {
    @apply font-extrabold text-4xl 
  }

  h2 {
    @apply font-extrabold text-3xl
  }

  h3 {
    @apply font-extrabold text-2xl
  }

  h4 {
    @apply font-extrabold text-xl
  }

  h5 {
    @apply font-extrabold text-lg
  }

  .my-react-select-container .my-react-select__menu {
    @apply bg-transparent z-40 text-left shadow-none mt-5 
  }


  .my-react-select-container .my-react-select__menu-list {
    @apply rounded-habaButton p-0 border-2 border-orangeH3;
    // divide-y divide-blueH6  divide-dashed
  }

  // .my-react-select__no-options-message {
  //   @apply w-full h-full bg-red-500 z-40
  // }


  // fa riferimento alla finestra di opzioni dei selettori, esempio il py-5 crea lo spazio verticale tra le varie opzioni
  .my-react-select-container .my-react-select__option {
    @apply text-blueH1 py-5 bg-smokeH hover:bg-blueH6 hover:cursor-pointer
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    @apply invert-0 dark:invert 
  }

  .my-react-select-container .my-react-select__dropdown-indicator,
  .my-react-select-container .my-react-select__input-container,
  .my-react-select-container .my-react-select__placeholder,
  .my-react-select-container .my-react-select__single-value {
    @apply text-blueH1 text-left
  }

  .my-react-select-container .my-react-select__multi-value, 
  .my-react-select-container .my-react-select__multi-value__label {
    @apply bg-orangeH3 text-smokeH
  }

  .my-react-select-container .my-react-select__control {
    // @apply border-0 h-[70px];
    @apply border-smokeH rounded-habaInput bg-smokeH
  }

  .my-react-select-container .my-react-select__control:hover {
    // @apply border-0 h-[70px];
    @apply border-orangeH3
  }

  .my-react-select-container .my-react-select__control:focus-within {
    @apply border-orangeH3 dark:border-orangeH3 border-2;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght@0,9..144,100..900;1,9..144,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght@0,9..144,100..900;1,9..144,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap'); 

body {

  min-height: 100vh;
  width: 100vw;
  letter-spacing: 1px;
  line-height: 1.55rem;
  margin: 0;
  padding: 0;
}



.titleLeftSide {
  color: theme('colors.blueH2');
}

input[type="date"]{
  -webkit-appearance: none
}

.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input-container:focus-within label {
  transform: translate(0, 12px) scale(0.8);
  color: theme('colors.blueH2');
}

.input-container .filled {
  transform: translate(0, 12px) scale(0.8);
}

.input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 30px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: theme('colors.greyH');
  line-height: 1;
  left: 24px;
}

.input-container input {
  // TODO => serve per tutto ma non per i multiselect
  min-height: 74px;
  padding: 24px 16px 4px 24px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}


.input-container input:valid+label {
  transform: translate(0, 12px) scale(0.8) !important;
  color: theme('colors.blueH2') !important;
}

.input-container-date input:valid {
  border: 1px solid theme('colors.blueH2') !important;
}

// .css-1dimb5e-singleValue {
//   color: theme('colors.blueH2') !important;
// }

.input-container-date input:focus {
  // box-shadow: 0 0 0 2px #79b1ff;
  border-color: theme('colors.blueH2');
  box-shadow: 0 0 0 1px theme('colors.blueH2') inset;
}

.input-container-text input:focus {
  // box-shadow: 0 0 0 2px #79b1ff;
  border-color: theme('colors.blueH2');
  box-shadow: 0 0 0 1px theme('colors.blueH2') inset;
}



.codeAreaSelectLight {
  background-color: theme('colors.smokeH') !important;
  border: 1px solid theme('colors.blueH2') !important;
  box-shadow: 0 0 0 1px theme('colors.blueH2') !important;
}

.codeAreaSelectDark {
  background-color: theme('colors.smokeH') !important;
  border: 1px solid theme('colors.blueH2') !important;
  box-shadow: 0 0 0 1px theme('colors.blueH2') !important;
}

.codeAreaSelectFocus {
  background-color: red !important;
  border: 1px solid theme('colors.blueH2') !important;
  box-shadow: unset !important;
}


// fa spazio nel selettore del cod area
.css-1u9des2-indicatorSeparator {
  display: none;
}

.select__value-container {
  padding-right: 0 !important;
  margin-right: 0 !important;
}

.select__indicator {
  padding: 0 !important
}

.curve {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: translate3d(0, 0, 0);
}

.curve svg {
  width: 170%;
  height: 120px;
}

.withoutGradient .shape-fill {
  fill: theme('colors.blueH2');
}


/** For mobile devices **/
@media (max-width: 767px) {
  .curve svg {
    width: 200%;
    height: 50px;
  }
}

.select__control {
  height: 100%;
}

.css-13cymwt-control {
  border-color: #ececec !important;
  background-color: unset !important;
  // border: 1px solid #ececec;
}

/* Second animation */
@keyframes slide-out {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

/* Second animation */
@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}



.centeredGradient2 {
  // background: radial-gradient(theme('colors.blueH4'), theme('colors.blueH1'));
  background: radial-gradient(circle, theme('colors.blueH4'), theme('colors.blueH2'));
}

.centeredGradient {
  // background: radial-gradient(theme('colors.blueH4'), theme('colors.blueH1'));
  background: radial-gradient(circle, theme('colors.blueH2'), theme('colors.blueH1'));
}

.centeredGradient3 {
  background: radial-gradient(circle, theme('colors.blueH1'), theme('colors.orangeH3'));
}

.editorial {
  background-color: theme('colors.blueH4');
  display: block;
  width: 100%;
  height: 60px;
  max-height: 60px;
  margin: 0;
  z-index: 5;
  // bottom:0;
  // position:absolute;
  // left:0px;
  float: left;
}

.parallax1>use {
  animation: move-forever1 10s linear infinite;

  &:nth-child(1) {
    animation-delay: -2s;
  }
}

.parallax2>use {
  animation: move-forever2 8s linear infinite;

  &:nth-child(1) {
    animation-delay: -2s;
  }
}

.parallax3>use {
  animation: move-forever3 6s linear infinite;

  &:nth-child(1) {
    animation-delay: -2s;
  }
}

.parallax4>use {
  animation: move-forever4 4s linear infinite;

  &:nth-child(1) {
    animation-delay: -2s;
  }
}

@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }

  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}

@keyframes move-forever3 {
  0% {
    transform: translate(85px, 0%);
  }

  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes move-forever4 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}



.cardtest {
  // box-shadow: 10px 10px;
  box-shadow: 0px 1px 0px 0px theme('colors.blueH2'), 1px 0px 0px 0px theme('colors.blueH2'), 1px 2px 0px 0px theme('colors.blueH2'), 2px 1px 0px 0px theme('colors.blueH2'), 2px 3px 0px 0px theme('colors.blueH2'), 3px 2px 0px 0px theme('colors.blueH2'), 3px 4px 0px 0px theme('colors.blueH2'), 4px 3px 0px 0px theme('colors.blueH2'), 4px 5px 0px 0px theme('colors.blueH2'), 5px 4px 0px 0px theme('colors.blueH2'), 5px 6px 0px 0px theme('colors.blueH2'), 6px 5px 0px 0px theme('colors.blueH2'), 6px 7px 0px 0px theme('colors.blueH2'), 7px 6px 0px 0px theme('colors.blueH2'), 7px 8px 0px 0px theme('colors.blueH2'), 8px 7px 0px 0px rgba(0, 0, 0, 0.22);
}

.cardtestorange {
  // box-shadow: 10px 10px;
  box-shadow: 0px 1px 0px 0px theme('colors.orangeH3'), 1px 0px 0px 0px theme('colors.orangeH3'), 1px 2px 0px 0px theme('colors.orangeH3'), 2px 1px 0px 0px theme('colors.orangeH3'), 2px 3px 0px 0px theme('colors.orangeH3'), 3px 2px 0px 0px theme('colors.orangeH3'), 3px 4px 0px 0px theme('colors.orangeH3'), 4px 3px 0px 0px theme('colors.orangeH3'), 4px 5px 0px 0px theme('colors.orangeH3'), 5px 4px 0px 0px theme('colors.orangeH3'), 5px 6px 0px 0px theme('colors.orangeH3'), 6px 5px 0px 0px theme('colors.orangeH3'), 6px 7px 0px 0px theme('colors.orangeH3'), 7px 6px 0px 0px theme('colors.orangeH3'), 7px 8px 0px 0px theme('colors.orangeH3'), 8px 7px 0px 0px rgba(0, 0, 0, 0.22);
}

.buttontest {
  // box-shadow: 10px 10px;
  box-shadow: 0px 1px 0px 0px theme('colors.blueH4'), 1px 0px 0px 0px theme('colors.blueH4'), 1px 2px 0px 0px theme('colors.blueH4'), 2px 1px 0px 0px theme('colors.blueH4'), 2px 3px 0px 0px theme('colors.blueH4'), 3px 2px 0px 0px theme('colors.blueH4'), 3px 4px 0px 0px theme('colors.blueH4'), 4px 3px 0px 0px theme('colors.blueH4'), 4px 5px 0px 0px theme('colors.blueH4'), 5px 4px 0px 0px theme('colors.blueH4'), 5px 6px 0px 0px theme('colors.blueH4'), 6px 5px 0px 0px theme('colors.blueH4'), 6px 7px 0px 0px theme('colors.blueH4'), 7px 6px 0px 0px theme('colors.blueH4'), 7px 8px 0px 0px theme('colors.blueH4'), 8px 7px 0px 0px rgba(0, 0, 0, 0.22);
}


/* For WebKit browsers (Chrome, Safari) */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default styling */
  appearance: none;
  width: 20px; /* Thumb width */
  height: 20px; /* Thumb height */
  background-color: theme('colors.orangeH2'); /* Thumb color */
  border-radius: 50%; /* Circular thumb */
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5); /* Optional: Add a shadow */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

input[type="range"]::-webkit-slider-thumb:hover {
  background-color: theme('colors.orangeH1'); /* Change color on hover */
}

/* For Mozilla browsers (Firefox) */
input[type="range"]::-moz-range-thumb {
  width: 20px; /* Thumb width */
  height: 20px; /* Thumb height */
  background-color: theme('colors.orangeH2'); /* Thumb color */
  border-radius: 50%; /* Circular thumb */
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5); /* Optional: Add a shadow */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

input[type="range"]::-moz-range-thumb:hover {
  background-color: theme('colors.orangeH1'); /* Change color on hover */
}

/* For IE and Edge browsers */
input[type="range"]::-ms-thumb {
  width: 20px; /* Thumb width */
  height: 20px; /* Thumb height */
  background-color: theme('colors.orangeH2'); /* Thumb color */
  border-radius: 50%; /* Circular thumb */
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5); /* Optional: Add a shadow */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

input[type="range"]::-ms-thumb:hover {
  background-color: theme('colors.orangeH1'); /* Change color on hover */
}



// ----
// CHAT



/* CSS talk bubble */
.talk-bubble {
  margin: 40px;
  display: inline-block;
  position: relative;
  width: 200px;
  height: auto;
  background-color: lightyellow;
}

.border-chat {
  border: 8px solid lightyellow;
}

.round-chat {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;

}





/*Right triangle, placed bottom left side slightly in*/
.tri-right.border.btm-left-in:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 30px;
  right: auto;
  top: auto;
  bottom: -40px;
  border: 20px solid;
  border-color: lightyellow transparent transparent lightyellow;
}

.tri-right.btm-left-in:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 38px;
  right: auto;
  top: auto;
  bottom: -20px;
  border: 12px solid;
  border-color: lightyellow transparent transparent lightyellow;
}

/* talk bubble contents */
.talktext {
  padding: 1em;
  text-align: left;
  line-height: 1.5em;
}

.talktext p {
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}

.hidden {
  display: none;
}

// $configuration_name: 'studenti_it';

// @import './custom/$configuration_name.scss';
@media (max-width: 767px) {
  html {
    font-size: 80%;
  }
}


.lf-player-container {
  min-width: 20vw;
}